.header {
	text-align: center;
  margin-bottom: 15px;
}

.header h1,
.header h2 {
  text-align: center;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #f1f1f1; /* Or any other background color */
}

.header p {
	text-align: left;
  margin-left: auto; /* Align to the center */
  margin-right: auto; 
	margin-top: 0;
	color: #666;
}

header a {
  display: inline; /* Keeps the link inline with the preceding text */
  margin-left: 0.5em; /* Adds space to the left of the link */
}

/* Apply a basic reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

/* Add a centered container for the main content */
.main-content {
  max-width: 1200px; /* or whatever maximum width you prefer */
  margin: 20px auto; /* Center the container */
  padding: 20px;

}

/* Style the container */
.form-container {
  padding: 20px;
  max-width: 100%; /* Allow the container to take up the full width */
  margin: auto;
	background-color: #e3ecec;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	text-align: center; /* Center text for all child elements */

}

/* Style the form elements */
.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align form elements to the start */
  flex-wrap: wrap; /* Allow items to wrap in smaller screens */
  gap: 1em; /* Add space between form elements */
}

label {
  font-weight: 600;
  color: #333;
  margin-right: 0.5em; /* Add space to the right of the label */
}

/* You can also add a flex container around each label-select pair for better alignment */
.form-group > div {
  display: flex;
  align-items: center;
  gap: 0.5em; /* Space between label and select within the flex container */
}

select {
  padding: 10px 15px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  color: #333;
  background-color: #f0f0f0;
  width: auto; /* Adjust width as needed */
}

/* Responsive tweaks */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
    align-items: stretch; /* Stretch form elements to full width */
  }

  label, select {
    width: 100%; /* Make label and dropdowns take full width on smaller screens */
  }
}
  
/* Style the results display */
.results {
margin-top: 20px;
}

/* Style the table */
.table-container {
overflow-x: auto;
}
  
table {
margin: 0 auto; /* This centers the table in the container */
border-collapse: collapse;
width: 100%; /* Make label and dropdowns take full width on smaller screens */
margin-top: 5px;
}

th, td {
text-align: left;
padding: 15px; /* Increase padding for more space */
border-bottom: 1px solid #ddd;
}

th {
background-image: linear-gradient(to right, #6ee2f5, #6454f0);
color: #ffffff;
border-radius: 8px 8px 0 0;
}

td {
color: #333;
font-size: 18px; /* Increase font size */
font-weight: 500; /* Make font bolder */
}

tbody tr:nth-child(odd) {
background-color: #f9f9f9;
}

/* Add some color and padding to the header */
h1 {
color: #6454f0;
padding-bottom: 10px;
margin-bottom: 20px;
border-bottom: 2px solid #6ee2f5;
}

/* Responsive tweaks */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  label, select {
    width: 100%; /* Make label and dropdowns take full width on smaller screens */
  }
}
  